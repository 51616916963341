<template>
    <div class="table-form">
        <div class="title" v-if="title">{{title}}</div>
        <table border="1">
            <tr v-for="header in headers" :key="header.value">
                <td :style="{width:header.width}">{{header.label}}</td>
                <td v-for="(formItem,index) in formList" :key="index">
                    <slot v-if="header.slot" :name="header.slot" :form="formItem" :index="index"></slot>
                    <div v-html="formItem[header.value]" v-else>{{formItem[header.value]}}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        headers: {
            type: Array,
            default(){
                return [];
            }
        },
        formList: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data() {
        return {
            
        };
    },
    methods: { 
        
    },
    mounted() {
        // console.log(this.headers,this.formList);
    }
};
</script>

<style lang="less" scoped>
td:nth-child(1){
    width: 15%;
    padding: 0 10px;
    background-color: #F0F2F5;
}
td{
    padding: 0 10px;
}
table{
    table-layout:fixed ;
}
.table-form .el-cascader /deep/ input{
    border: 1px solid #DCDFE6;
}
</style>