<template>
    <div class="contrast">
        <Topbar title="干部对比">
            <template slot="menu">
                <Menu :menuList="menuList" v-model="pageType"></Menu>
            </template>
            <template slot="right">
                <i class="back iconfont icon-shanchu" @click="back"></i>
            </template>
        </Topbar>
        <div class="body">
            <div class="content" v-if="pageType === 'base'">
                <RowTable :headers="headers0" :formList="formList0">
                    <template slot="select" slot-scope="{ form , index }">
                        <div class="user">
                            <div class="info" v-if="form.id">
                                <div class="img-container" @click="goDetail(form)">
                                    <img :src="form.avatar" alt>
                                </div>
                                <p class="name" @click="goDetail(form)">{{form.name}}</p>
                                <p class="org-text" :title="form.orgId">{{form.orgId}}</p>
                            </div>
							<el-cascader 
								:ref="`es${index}`" 
								:disabled="!!form.deleted"
								class="mt10" size="small"
								:options="orgList" 
								:props="{
                                    label: 'name',
                                    value: 'id'
                                }" 
								@change="handleChange($event, index)"
								:value="selectedValList[index]"
								:show-all-levels="false"
        						clearable>
   							</el-cascader>
                            <i v-if="form.id" class="close iconfont icon-shanchu" @click="delUser(index)"></i>
                            <div class="exchange iconfont icon-jiaohuan" @click="exchange(index)" v-if="hasExChange(index)"></div>
                        </div>
                    </template>
                </RowTable>
                <RowTable title="基本信息" :headers="headers1" :formList="formList1"></RowTable>
                <RowTable title="学历学位信息" :headers="headers2" :formList="formList2"></RowTable>
                <RowTable title="职务职级信息" :headers="headers3" :formList="formList3"></RowTable>
                <RowTable title="简历信息" :headers="headers4" :formList="formList4"></RowTable>
                <RowTable v-if="!$getCurrentLib()" title="考核情况" :headers="headers5" :formList="formList5"></RowTable>
				<div v-else>
					<RowTable title="奖惩信息" :headers="headers6" :formList="formList5"></RowTable>	
                	<RowTable title="年度考核信息" :headers="headers7" :formList="formList5"></RowTable>	
				</div>
            </div>
            <div class="content" v-if="pageType === 'annex'">
                <Annex :formList="formList0"></Annex>
            </div>
        </div>
    </div>
</template>

<script>
import Topbar from '@/components/topbar';
import Tabs from '@/components/tabs';
import RowTable from './RowTable.vue';
import { getJobList, getOrgList , userDetail } from '@/api/hrAPI.js';
import { getIdListInTree } from '@/utils/handle.js';
import { getContrastList , setContrastList } from '@/api/loginAPI.js';
import Annex from './Annex.vue';
import { duties , otherDuties } from '@/components/table/data.js';

const map = {}  // id: pids

export default {
    components: {
        Topbar,
        Tabs,
        RowTable,
        Annex,
    },
    data(){
        return {
            menuList: [
                {
                    label: '基础信息对比',
                    value: 'base'
                },
                {
                    label: '附件详情对比',
                    value: 'annex'
                },
            ],
            pageType: 'base',
            headers0: [
                {
                    label: '选择干部',
                    slot: 'select'
                }
            ],
            formList0: [],
            headers1: [
                {
                    label: '性别',
                    value: 'sex'
                },
                {
                    label: '出生年月',
                    value: 'birthday'
                },
                {
                    label: '年龄',
                    value: 'age'
                },
                {
                    label: '民族',
                    value: 'nation'
                },
                {
                    label: '籍贯',
                    value: 'nativePlace'
                },
                {
                    label: '出生地',
                    value: 'birthPlace'
                },
                {
                    label: '政治面貌',
                    value: 'politicalAppearance'
                },
                {
                    label: '入党时间',
                    value: 'joinPartyTime'
                },
                {
                    label: '参加工作时间',
                    value: 'joinWorkTime'
                },
                {
                    label: '健康状况',
                    value: 'healthStatus'
                },
                // {
                //     label: '专业技能职务',
                //     value: 'specializedSkills'
                // },
                {
                    label: this.$getCurrentLib() ? '熟悉专业/有何专长' : '专业方向',
                    value: 'majorStrength'
                },
            ],
            formList1: [],
            headers2: [
                {
                    label: '最新信息',
                    value: 'info'
                }
            ],
            formList2: [],
			headers3: [],
            formList3: [],
            headers4: [
                {
                    label: '简历',
                    value: 'info'
                }
            ],
            formList4: [],
            headers5: [
                {
                    label: '奖惩信息',
                    value: 'rewardPunishmentState'
                },
                {
                    label: '年度考核结果',
                    value: 'checkResult'
                },
                {
                    label: '群众满意度排名',
                    value: 'appointRemoveReason'
                },
            ],
            headers6: [
                {
                    label: '奖惩情况',
                    value: 'rewardPunishmentState'
                },],
            headers7: [
                {
                    label: '年度考核结果',
                    value: 'checkResult'
                }
            ],
            formList5: [],
            orgList: [],
            jobList: [],
            annexList: [],
            orgUserList: [],
			contrastUserList: [],
			selectedValList: [],
			// loading: null
        }
    },
    computed: {
		libName() {
			return `hrms${this.$getCurrentLib() ? '_' : ''}${this.$getCurrentLib()}`
		},
		contrastUserIds() {
			return this.contrastUserList.map(user => user.id)
		}
    },
    async created(){ 
		await this.getInfo()
		this.init()
    },
    methods: {
		async init() {
			let loading = null
			try {
				loading = this.$loading({
          			lock: true,
          			text: '加载中',
          			pinner: 'el-icon-loading',
        		});
				this.resetForm()
        		await this.getTableData();
				this.contrastUserIds.forEach((id, idx) => {
					this.$set(this.selectedValList, idx, map[id] || [])
				})
			} finally{
				loading.close()
			}
		},
		async getInfo() {
			this.headerInit()
			const [orgList, jobList]  = await Promise.all([getOrgList(),getJobList()])
        	this.orgList = orgList;
        	this.jobList = jobList;
			this.traverse(this.orgList)
			Object.keys(map).forEach(key => {
				map[key].shift()
			})
		},
		traverse(list, pids=['0']) {
			list.forEach(item => {
				// 需要显示完整路径
				item.paths = [...pids, item.id]
				item.children = item.children || []
				item.userList = item.userList || []
				item.children.push(...item.userList)

				if(item.type) {
					if(item.children && !item.children.length) {
						item.disabled = true
					} else {
						this.traverse(item.children, item.paths)
					}	
				} else {
					map[item.id] = item.paths
					item.children = null
				}
			})	
		},
		headerInit() {
			// console.log(this.$route)
			if(this.$route.name === 'contrast') {
				this.headers3 = duties.map(item => {
                    return {
                        ...item,
                        slot: '',
                    }
                });         //  职务信息区分
			} else {
				this.headers3 = otherDuties.map(item => {
                    return {
                        ...item,
                        slot: '',
                    }
                });
			}
		},
        async getTableData(){
            // console.log(this.EnumType.ZZMM);
			const { contrastUserIds = [] } = await getContrastList({name: this.libName})
			
			for(let i = 0; i < 4; i++) {
				 const id = contrastUserIds[i]
				 if(id) {
					const user = await userDetail({ id })
				 	if(user) {
						this.contrastUserList.push(user)
						this.initList(user, i)
					}
				 }
			}

			// this.contrastUserList.forEach((user, i) => this.initList(user, i))
        },
		resetForm() {
			const name = 'formList'
			const idx = Array(6).fill(null)
			idx.forEach((_, i) => {
				this[`${name}${i}`] = [{}, {}, {}, {}]
			})
			this.contrastUserList = []
			this.selectedValList = []
		},
        initEducationalInfo(educationInfoList){
            let res = '';
            educationInfoList.forEach(item => {
                const str = `
                    ${item.startTime ? `${item.startTime}-${item.endTime},` : ''}
                    ${this.EnumType.education[item.qualification] && this.EnumType.education[item.qualification] + ',' || ''}
                    ${this.EnumType.degree[item.degree] && this.EnumType.degree[item.degree] + ',' || ''}
                    ${this.EnumType.educationalSystem[item.educationalSystem] && this.EnumType.educationalSystem[item.educationalSystem] + ',' || ''}
					${this.EnumType.major[item.major] && this.EnumType.major[item.major] + ',' || ''}
                    ${item.institutionsMajors || ''}`;
                res += str.replace(/\n/,'') + '<br/>' ;
            })

            return { info: res };
        },
        initJobInfo(positionInfo){
            let res = {};
            res.orgId = getIdListInTree(this.orgList,positionInfo.orgId,'name').join(',');
            // console.log(res.orgId);
            res.currentPositionIds = this.jobList.filter(item => positionInfo.currentPositionIds && positionInfo.currentPositionIds.includes(item.id)).map(item => item.name).join(',');
            res.proposedPositionIds = this.jobList.filter(item => positionInfo.proposedPositionIds && positionInfo.proposedPositionIds.includes(item.id)).map(item => item.name).join(',');
            res.retirementPositionIds = this.jobList.filter(item => positionInfo.retirementPositionIds && positionInfo.retirementPositionIds.includes(item.id)).map(item => item.name).join(',');
            return res;
        },
        initEasyInfo(biographicalNotesInfoList){
            let res = '';
            biographicalNotesInfoList.forEach(item => {
                const str = `${item.startTime ? `${item.startTime}-${item.endTime},` : ''}${item.description || ''}`;
                res += str.replace(/\n/,'') + '<br/>' ;
            })

            return { info: res, };
        },
        handleChange(val = [],index){
			const userId = val[val.length -1]
			if(this.contrastUserIds.includes(userId)) {
				this.$set(this.selectedValList, index, [])
				return this.$message.warning('该干部已经加入对比，无需重复加入!');
			}
			if(!userId) return    // delUser的时候也会触发这里
			const contrastUserIds = [...this.contrastUserIds] 
			contrastUserIds.splice(index, 1, userId)
			this.setContrastList(contrastUserIds)
        },
		async setContrastList(contrastUserIds) {
			await setContrastList({
				name: this.libName,
				contrastUserIds
			})
			this.init()
		},
        initList(user,index){
            this.$set(this.formList1,index,{
                ...user,
                sex: this.EnumType.sex[user.sex],
				politicalAppearance: this.EnumType.ZZMM[user.politicalAppearance]
            })
            this.$set(this.formList2,index,this.initEducationalInfo(user.educationInfoList));
            this.$set(this.formList3,index,this.initJobInfo(user.positionInfo));
            this.$set(this.formList4,index,this.initEasyInfo(user.biographicalNotesInfoList));
            this.$set(this.formList5,index,user.assessmentSituationsInfo || {});
            this.$set(this.formList0,index,{
                name: user.name,
                orgId: this.formList3[index].orgId,
                avatar: user.avatar,
                id: user.id,
                fileInfoList: user.fileInfoList,
				deleted: user.deleted
            })
        },
        delUser(index){
			const contrastUserIds = [...this.contrastUserIds]  
			contrastUserIds.splice(index,1)
			this.setContrastList(contrastUserIds)
        },
        back(){
            this.$router.back();
        },
        goDetail(data){
			if(data.deleted) {
				return this.$message.warning("该用户已被删除，禁止查看详情！！！")
			}
            this.$router.push({
                path: '/user-detail',
                query: {
                    userId: data.id
                }
            })
        },
        hasExChange(index){
			if(index === 3) return false
			return this.formList0[index]?.id && this.formList0[index+1]?.id;
        },
        exchange(index){
			const contrastUserIds = [...this.contrastUserIds] 
			const userId = contrastUserIds.splice(index, 1)[0]
			contrastUserIds.splice(index+1, 0 ,userId)
			this.setContrastList(contrastUserIds)
        }
    },
}
</script>

<style lang="less" scoped>
.contrast{
    width: 100vw;
    height: 100vh;
    background-color: #EFF3F7;
    .back{
        cursor: pointer;
        padding: 5px;
        float: right;
    }
    .body{
        width: 100%;
        height: calc(100% - 50px);
        padding: 10px 20px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;
        .content{
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: 20px;
            overflow: auto;
            .table-form{
                margin-top: 15px;
            }
            .user{
                width: 100%;
            	height: 270px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-sizing: border-box;
                position: relative;
                .info{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    .img-container{
                        width: 100px;
                        height: 130px;
                        border: 1px solid #ccc;
                        cursor: pointer;
                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .name{
                        color: #FF0000;
                        font-size: 20px;
                        margin: 10px 0;
                        cursor: pointer;
                    }
                    .org-text{
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: center;
                    }
                }
                .close{
                    position: absolute;
                    top: 5px;
                    right: -5px;
                    cursor: pointer;
                    padding: 5px;
                }
                .exchange{
                    width: 33px;
                    height: 33px;
                    border: 1px solid #d9d9d9;
                    border-radius: 50%;
                    box-sizing: border-box;
                    position: absolute;
                    right: -26px;
                    top: 30%;
                    background-color: #fff;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #999;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>