<template>
    <div class="annex">
        <div class="left">
            <div class="form">
                <div class="form-item">
                    <span class="label">干部</span>
                    <el-select size="small" v-model="leftUserId" @change="leftValueChange">
                        <el-option v-for="(item,index) in filterFormList" :key="index" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </div>
                <div class="form-item">
                    <span class="label">附件</span>
                    <el-select size="small" v-model="leftFilePath">
                        <el-option-group
                            v-for="group in leftList"
                            :key="group.value"
                            :label="group.label">
                            <el-option v-for="(item,index) in group.children" :key="index" :value="item.filePath" :label="item.fileName"></el-option>
                        </el-option-group>
                        
                    </el-select>
                </div>
            </div>
            <div class="preview">
                <FileViewer :filePath="leftFilePath"></FileViewer>
            </div>
        </div>
        <div class="right">
            <div class="form">
                <div class="form-item">
                    <span class="label">干部</span>
                    <el-select size="small" v-model="rightUserId" @change="rightValueChange">
                        <el-option v-for="(item,index) in filterFormList" :key="index" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </div>
                <div class="form-item">
                    <span class="label">附件</span>
                    <el-select size="small" v-model="rightFilePath">
                        <el-option-group
                            v-for="group in rightList"
                            :key="group.value"
                            :label="group.label">
                            <el-option v-for="(item,index) in group.children" :key="index" :value="item.filePath" :label="item.fileName"></el-option>
                        </el-option-group>
                        
                    </el-select>
                </div>
            </div>
            <div class="preview">
                <FileViewer :filePath="rightFilePath"></FileViewer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        formList: {
            type: Array,
            default(){
                return [];
            }
        },

    },
    computed: {
        annexHeaders(){
            const path = this.$route.path;
            if(path.endsWith('/cadre')){
                return this.$store.getters.dictMap['hrms_dir_cadre'];
            }else if(path.endsWith('/dean')){
                return this.$store.getters.dictMap['hrms_dir_dean'];
            }else{
                return this.$store.getters.dictMap['hrms_dir'];
            }
        },
		filterFormList() {
			return this.formList.filter(item => Object.keys(item).length !== 0)
		}
    },
    data(){
        return {
            leftUserId: '',
            leftList: [],
            leftFilePath: '',
            rightUserId: '',
            rightList: [],
            rightFilePath: '',
        }
    },
    methods: {
        leftValueChange(value){
            // const list = this.formList.find(item => item.id === value).fileInfoList;
            const list = this.filterFormList.find(item => item.id === value).fileInfoList;
			console.log(this.annexHeaders)
            this.leftList = this.annexHeaders.map(item => {
                return {
                    label: item.label,
                    value: item.id,
                    children: list.filter(file => file.dirName === item.label)
                }
            }).filter(item => !!item.children.length)
            this.leftFilePath = '';
        },
        rightValueChange(value){
            const list = this.filterFormList.find(item => item.id === value).fileInfoList;
            // const list = this.formList.find(item => item.id === value).fileInfoList;
            this.rightList = this.annexHeaders.map(item => {
                return {
                    label: item.label,
                    value: item.id,
                    children: list.filter(file => file.dirName === item.label)
                }
            }).filter(item => !!item.children.length)
            this.rightFilePath = '';
        }
    },
    mounted(){},
}
</script>

<style lang="less" scoped>
.annex{
    width: 100%;
    height: 100%;
    display: flex;
    > div{
        width: 50%;
        height: 100%;
        flex: 1;
    }
    .form{
        display: flex;

        .form-item{
            display: flex;
            align-items: center;
            margin-left: 30px;
            .label{
                margin-right: 15px;
            }
        }

    }
    .preview{
        padding-top: 16px;
        height: calc(100% - 32px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}
</style>